import React from "react";
import "../../styles/index.css";

export default function takeoutInfo() {
  return (
    <div>
      <h3>
        <u>Takeout Info</u>
      </h3>
      <ol>
        <li>There is no minimum order price for takeout orders.</li>
        <br />
        <li>
          Typically, your order will be ready for pickup in about 20 minutes. However, an
          order may take longer.
        </li>
        <br />
        <li>
          We accept Visa, Mastercard, American Express, and Cash as methods of
          payment.
        </li>
      </ol>
      <br />
      <h3>
        <u>Delivery Info</u>
      </h3>
      <ol>
        <li>We only deliver orders that have a total over $20 dollars.</li>
        <br />
        <li>
          All delivery orders have a delivery fee of $1.90. The delivery fee,
          however, is not a driver tip!
        </li>
        <br />
        <li>
          We accept Visa, Mastercard, American Express, and Cash as payment
        </li>
        <br />
        <li>Our delivery radius is about 3.5 miles from the restaurant</li>
      </ol>
    </div>
  );
}
