import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function aboutUs() {
  return (
    <StaticQuery
      query={graphql`
        query {
          promo: file(relativePath: { eq: "promo.png" }) {
            childImageSharp {
              fluid(maxWidth: 240, maxHeight: 240) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <h2>
            <u>About Us:</u>
          </h2>
          <div className="aboutFlex">
            <Img
              alt="Chopstick Express's Secondary Logo with California Rolls in the background."
              fluid={data.promo.childImageSharp.fluid}
              className="image"
            />
            <p className="text">
              Having served Chinese food in the St. Petersburg, FL community for
              over a decade, our restaurant is known for our consistent quality.
              We offer a variety of delicious dishes such as General Tso's
              Chicken, Mongolian Beef, and Krab Rangoons. Or are you looking
              foods that are extremely healthy, yet tasty? Then, try out our
              grilled dishes, such as our Teriyaki Chicken and Teriyaki Seafood.
              In addition, our menu also includes a tasty assortment of fresh,
              hand rolled sushi. Try traditional rolls like the California Roll
              or a specialty like our Magic Roll. No matter your tastes, our
              menu has something for you! If not, we can make you your very own
              dish, just let us know! Our affordable menu, convenient location,
              and fresh ingredients make our restaurant one of the top choices
              for Chinese food in St. Petersburg. Order online now from
              Chopstick Express to enjoy the best Chinese food in town! We do
              both delivery and takeout!
            </p>
          </div>
        </div>
      )}
    />
  );
}
