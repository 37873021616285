import React from "react";
import "../../styles/index.css";

export default function restaurantInfo() {
  return (
    <div>
      <h2 className="centerText">Restaurant Information</h2>
      <div className="hoursAndPhone">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>
            <u>Hours</u>
          </h3>
          <p>
            <b>Monday:</b> 10:30 A.M. to 10:00 P.M.
          </p>
          <p>
            <b> Tuesday:</b> Closed
          </p>
          <p>
            <b>Wednesday - Sunday:</b> 10:30 A.M. to 10:00 P.M.
          </p>
        </div>
        <div className="phone">
          <h3>
            <u>Contact Us</u>
          </h3>
          <p>
            <b>Phone 1:</b> (727)-526-8999
          </p>
          <p>
            <b>Phone 2:</b> (727)-526-0999
          </p>
        </div>
      </div>
    </div>
  );
}
