import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import "../styles/index.css";
import AboutUs from "../components/home/aboutUs";
import OrderButton from "../components/home/orderButton";
import RestaurantInfo from "../components/home/restaurantInfo";
import TakeoutInfo from "../components/home/takeoutInfo";

const Home = () => (
  <Layout location="/">
    <Helmet>
      <title>Chopstick Express | Chinese Food | St. Petersburg, FL</title>
      <meta charset="utf-8" />
      <meta
        name="description"
        content="Chopstick Express offers Chinese delivery and takeout in St. Petersburg. Order online from our menu now!"
      />
      <link rel="canonical" href="https://www.chopstickfl.com/" />
    </Helmet>
    <div className="homePage">
      <br />
      <h1>
        <b>Chopstick Express </b> <br />
        <span style={{ fontSize: 24, fontWeight: 500 }}>
          Chinese Delivery & Takeout
        </span>
      </h1>
      <h3>3946 4th St N, St. Petersburg, FL</h3>
      <br />
      <div className="content">
        <div className="rearrange">
          <div style={{ order: 2 }}>
            <AboutUs />
          </div>
          <br />
          <br />
          <div style={{ order: 1 }}>
            <div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 16,
                }}
              >
                Now using our new ordering experience!
              </span>
              <OrderButton />
            </div>
          </div>
        </div>
        <RestaurantInfo />
        <br />
        <TakeoutInfo />
        <br />
      </div>
    </div>
  </Layout>
);

export default Home;
